const TaxAccountantSideMenu = [
  {
    title: 'Dashboard',
    is_heading: false,
    is_active: false,
    link: {
      name: 'tax-accountant.dashboard'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-home-4-line'
  },
  {
    title: 'Pending Approvals',
    is_heading: false,
    is_active: false,
    link: {
      name: 'tax-accountant.pending-approvals'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-currency-line'
  },
  {
    title: 'Maintenance',
    name: 'sidebar.maintenance',
    is_heading: false,
    is_active: false,
    link: '',
    class_name: '',
    is_icon_class: true,
    icon: 'ri-folder-2-line',
    children: [
      {
        title: 'Suppliers',
        is_active: false,
        link: {
          name: 'tax-accountant.suppliers'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-store-3-line'
      },
      {
        title: 'Tax Codes',
        is_active: false,
        link: {
          name: 'tax-accountant.tax-codes'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-store-3-line'
      }
    ]
  },
  {
    title: 'Reports',
    name: 'sidebar.reports',
    is_heading: false,
    is_active: false,
    link: '',
    class_name: '',
    is_icon_class: true,
    icon: 'ri-folder-chart-line',
    children: [
      {
        title: 'Approval Histories',
        is_active: false,
        link: {
          name: 'tax-accountant.approval-histories'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-file-excel-2-line'
      }
    ]
  }
]

export default TaxAccountantSideMenu
