const DisbursementSideMenu = [
  {
    title: 'Dashboard',
    is_heading: false,
    is_active: false,
    link: {
      name: 'disbursement.dashboard'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-home-4-line'
  },
  {
    title: 'Pending Transactions',
    is_heading: false,
    is_active: false,
    link: {
      name: 'disbursement.pending-transactions'
    },
    class_name: '',
    is_icon_class: true,
    icon: 'ri-currency-line'
  },
  {
    title: 'Maintenance',
    name: 'sidebar.maintenance',
    is_heading: false,
    is_active: false,
    link: '',
    class_name: '',
    is_icon_class: true,
    icon: 'ri-folder-2-line',
    children: [
      {
        title: 'Budgets',
        is_active: false,
        link: {
          name: 'disbursement.budgets'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-bank-fill'
      },
      {
        title: 'Budget Actuals',
        is_active: false,
        link: {
          name: 'disbursement.budget-actuals'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-file-word-line'
      },
      {
        title: 'Item Descriptions',
        is_active: false,
        link: {
          name: 'disbursement.item-descriptions'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-file-excel-2-line'
      }
    ]
  },
  {
    title: 'Reports',
    name: 'sidebar.reports',
    is_heading: false,
    is_active: false,
    link: '',
    class_name: '',
    is_icon_class: true,
    icon: 'ri-folder-chart-line',
    children: [
      {
        title: 'Transaction Histories',
        is_active: false,
        link: {
          name: 'disbursement.transaction-histories'
        },
        class_name: '',
        is_icon_class: true,
        icon: 'ri-file-excel-2-line'
      }
    ]
  }
]

export default DisbursementSideMenu
