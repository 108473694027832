<template>
  <div class="iq-sidebar">
    <div class="iq-sidebar-logo d-flex justify-content-between">
      <router-link :to="{ name: `${user ? user.role : 'admin'}.dashboard`}">
        <!-- <div class="iq-light-logo">
          <img
            src="@/assets/images/aljay-logo.png"
            class="img-fluid"
            alt="logo"
          >
        </div>
        <div class="iq-dark-logo">
          <img
            src="@/assets/images/aljay-logo.png"
            class="img-fluid"
            alt="logo"
          >
        </div> -->
        <span>&nbsp;</span>
      </router-link>
      <div class="iq-menu-bt-sidebar">
        <div class="iq-menu-bt align-self-center">
          <div
            class="wrapper-menu"
            @click="sidebarMini"
          >
            <div class="main-circle">
              <i class="ri-arrow-left-s-line" /></div>
            <div class="hover-circle">
              <i class="ri-arrow-right-s-line" /></div>
          </div>
        </div>
      </div>
    </div>
    <div id="sidebar-scrollbar">
      <nav class="iq-sidebar-menu">
        <CollapseMenu
          :items="menuItems"
          :open="true"
          :sidebar-group-title="true"
        />
      </nav>
      <div class="p-3" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { core, APPNAME } from '@/config/pluginInit'
import CollapseMenu from '@/components/core/menus/CollapseMenu'
import {
  AdminSideMenu,
  AuditorSideMenu,
  TaxAccountantSideMenu,
  DisbursementSideMenu,
  UserSideMenu
} from '@/navigation'

export default {
  name: 'SidebarStyle',
  components: {
    CollapseMenu
  },

  data () {
    return {
      appName: APPNAME
    }
  },

  computed: {
    ...mapGetters({
      user: 'auth/user'
    }),

    menuItems () {
      const user = this.$store.getters['auth/user']

      if (user) {
        if (user.role === 'admin') {
          return AdminSideMenu
        }

        if (user.role === 'auditor') {
          return AuditorSideMenu
        }

        if (user.role === 'tax-accountant') {
          return TaxAccountantSideMenu
        }

        if (user.role === 'disbursement') {
          return DisbursementSideMenu
        }

        if (user.role === 'user') {
          return UserSideMenu
        }
      }

      return []
    }
  },

  mounted () {
    core.SmoothScrollbar()
  },

  methods: {
    sidebarMini () {
      core.triggerSet()
    }
  }
}
</script>
